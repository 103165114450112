const L = require('leaflet');
require('leaflet.markercluster/dist/leaflet.markercluster');
require('leaflet.awesome-markers/dist/leaflet.awesome-markers');
import 'leaflet/dist/leaflet.css';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';

import 'leaflet-geosearch/assets/css/leaflet.css';
const { SearchBox } = require('leaflet-searchbox');
import 'leaflet-searchbox/dist/style.css';

import 'leaflet.awesome-markers/dist/leaflet.awesome-markers.css';
import SearchProvider from './searchProvider.js';

const points = require('./output.json');
var map = L.map('map', { maxZoom: 17 }).setView([51.3396955, 12.3730747], 13);

L.tileLayer('https://tiles.kuldig.de/styles/klokantech-basic/{z}/{x}/{y}.png', {
    attribution:
        '<a href="https://kuldig.de" target="_blank">&copy;  KULDIG</a> <a href="http://www.openmaptiles.org/" target="_blank">&copy; OpenMapTiles</a> <a href="http://www.openstreetmap.org/about/" target="_blank">&copy; OpenStreetMap contributors</a>',
}).addTo(map);
map.zoomControl.setPosition('topright');

var squareRedMarker = L.AwesomeMarkers.icon({
    icon: 'home',
    prefix: 'ion',
    markerColor: 'red',
});

var squareGreenMarker = L.AwesomeMarkers.icon({
    icon: 'search',
    prefix: 'ion',
    markerColor: 'green',
});

//var markers = L.markerClusterGroup();
for (var i = 0; i < points.length; i++) {
    var a = points[i];
    var title = a.title;
    var description = a.description;
    var marker = L.marker(new L.LatLng(a.coordinates[1], a.coordinates[0]), { title: title, icon: squareRedMarker });
    marker.bindPopup(`${title}<br>${description}`);
    map.addLayer(marker);
    a.marker = marker;
}
//map.addLayer(markers);
const searchProvider = new SearchProvider({ data: points });
var control = L.control.searchbox({
    position: 'topleft',
    provider: searchProvider, //new leafletGeosearch.OpenStreetMapProvider(),
    //resultItemClickCallback: resultItemClickCallback,
    // suggest: suggest,
    // remove these if dont need sidebar
    resultItemTemplate: `<a href="#" data-x="<%- item.x %>" 
    data-y="<%- item.y %>" 
    data-label="<%- item.label %>" 
    data-class="<%- item.raw.class %>" data-type="<%- item.raw.type %>" data-display_name="<%-item.raw.display_name%>">\${item.label}</a>`,
    sidebarTitleText: 'Konspirative Wohnungen',
    sidebarMenuItems: {
        Items: [
            { type: 'button', name: 'Einführung', onclick: "toggleOverlay()", icon: 'icon-cloudy' },
            { type: 'button', name: 'Projektbeschreibung', onclick: "showPage('about')", icon: '' },
            { type: 'button', name: 'Feldbeschreibungen', onclick: "showPage('desc')", icon: '' },
            { type: 'button', name: 'Beispielobjekt „Juwel“', onclick: "showPage('example')", icon: '' },
            { type: 'link', name: 'Impressum & Datenschutz', href: "http://www.runde-ecke-leipzig.de/index.php?id=63&L=82", icon: '' },
        ],
    },
});

map.addControl(control);
// workaround
control.options.provider = searchProvider;

let previousMarkedMarker = null;

map.on('geosearch/showlocation', x => {
    if (previousMarkedMarker) {
        previousMarkedMarker.setIcon(squareRedMarker).update();
    }
    const point = points.find(p => `${p.coordinates[0]}` === x.location.x && `${p.coordinates[1]}` === x.location.y);
    if (point) {
        console.log('marker', marker);
        point.marker.setIcon(squareGreenMarker).update();
        previousMarkedMarker = point.marker;
        point.marker.openPopup();
        map.setView([point.coordinates[1], point.coordinates[0]], 16);
    }
});
