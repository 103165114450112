import FlexSearch from 'flexsearch';
import striptags from 'striptags';
export default class SearchProvider {
    index = null;
    constructor(options = {}) {
        this.options = options;
        if (!this.options.data || !Array.isArray(this.options.data)) {
            throw new Error('SearchProvider: no data provided in options');
        }

        this.index = FlexSearch.create({
            tokenize: 'full',
            doc: {
                id: 'id',
                field: ['title', 'description'],
            },
        });

        for (let i = 0; i < this.options.data.length; i++) {
            const entry = this.options.data[i];
            this.index.add({
                id: i,
                title: striptags(entry.title),
                description: striptags(entry.description),
                coordinates: entry.coordinates,
            });
        }
    }

    async search({ query }) {
        const results = this.index.search(query);
        console.log('results', results);
        return results.map(r => {
            let label = r.title;
            if (label.toLowerCase().indexOf(query.toLowerCase()) === -1) {
                const i = r.description.toLowerCase().indexOf(query.toLowerCase());
                if (i > -1) {
                    label += '<br>... ' + r.description.substr(Math.max(0, i - 20), 40) + ' ...';
                }
            }
            return {
                x: r.coordinates[0],
                y: r.coordinates[1],
                label,
                raw: r,
            };
        });
    }
}
